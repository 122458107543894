import { To } from 'history';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import CenterPageLayoutNew from 'web/components/CenterPageLayoutNew';
import LoginForm from 'web/components/LoginForm';
import ScreenTracker from 'web/components/ScreenTracker';
import { BloggingImageSquare, TwoColumnContainer } from 'web/components/login-signup';
import UserContext from 'web/components/UserContext';
import BodyBackground from 'web/styles/BodyBackground';

const LoginTitle = styled.h1`
  margin-top: 20px;
`;

const Login = () => {
  const location = useLocation();
  const { user, loggingIn } = useContext(UserContext);

  if (loggingIn) {
    return <></>;
  }

  if (user) {
    const state = location.state as { from?: To };
    return <Navigate to={state?.from || '/home'} replace />;
  }

  return (
    <>
      <Helmet title="Login" />
      <ScreenTracker screenName="Login" />
      <BodyBackground color="#FFEBE0" />
      <CenterPageLayoutNew>
        <TwoColumnContainer>
          <div>
            <LoginTitle>Login</LoginTitle>
            <LoginForm />
          </div>
          <BloggingImageSquare />
        </TwoColumnContainer>
      </CenterPageLayoutNew>
    </>
  );
};

export default Login;
