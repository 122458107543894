import React from 'react';
import styled, { css } from 'styled-components';
import themeConstants from 'web/styles/themeConstants';
import { LinkUnstyled } from './elements';
import Logo from './Logo';

const Spacer = styled.div`
  flex: 1 0 auto;
`;

const HeaderContainer = styled.header`
  height: 100px;

  display: flex;
  align-items: center;
`;

const AppHeader = () => {
  return (
    <HeaderContainer>
      <LinkUnstyled to="/">
        <Logo gradient={{ from: '#ffc79c', to: '#ff6771' }} />
      </LinkUnstyled>
      <Spacer />
    </HeaderContainer>
  );
};

const Content = styled.div``;

const Header = styled.div``;
const Footer = styled.div``;

const CenterPage = styled.div<{ wide?: boolean }>`
  ${Header} {
    padding: 0 16px;
  }
  ${Content} {
    padding: 16px;
    ${themeConstants.media.md} {
      padding: 16px 64px;
    }

    border-radius: ${themeConstants.borderRadius.lg};
    box-shadow: ${themeConstants.boxShadow.lg};
    background-color: white;
  }

  ${themeConstants.media.md} {
    display: grid;
    grid-template-columns: 1fr minmax(700px, min-content) 1fr;
    grid-template-rows: min-content auto minmax(46px, 1fr) min-content;
    min-height: 100vh;
    grid-template-areas: 'header header header' '. content .' '. . .' 'footer footer footer';

    ${Header} {
      grid-area: header;
      padding: 0 68px;
    }

    ${Footer} {
      grid-area: footer;
    }

    ${Content} {
      grid-area: content;

      h1:first-of-type {
        margin-top: 20px;
      }
    }
  }

  ${themeConstants.media.lg} {
    grid-template-columns: 1fr minmax(940px, min-content) 1fr;
  }

  ${({ wide }) =>
    wide &&
    css`
      ${themeConstants.media.xl} {
        grid-template-columns: 1fr minmax(1200px, min-content) 1fr;
      }
    `}
`;

const FakeFootert = styled.div`
  height: 100px;
`;

const CenterPageLayoutNew = ({ children, wide }: { children: React.ReactNode; wide?: boolean }) => (
  <CenterPage wide={wide}>
    <Header>
      <AppHeader />
    </Header>
    <Content>{children}</Content>
    <Footer>
      <FakeFootert />
    </Footer>
  </CenterPage>
);

export default CenterPageLayoutNew;
