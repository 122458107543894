import { fetchSignInMethodsForEmail, signInWithEmailAndPassword } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { createPath, To } from 'history';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import {
  Button,
  FormError,
  FormGroup,
  InlineButton,
  Input,
  Label,
  LabelText,
  LinkStyled,
} from 'web/components/elements';
import useErrorReporter from 'web/hooks/useErrorReporter';
import useMountedRef from 'web/hooks/useMountedRef';
import themeClasses from 'web/styles/themeClasses.css';
import useAuth from './FirebaseContext/useAuth';
import useFunctions from './FirebaseContext/useFunctions';
import Spinner from './Spinner';
import useTracking from './TrackingContext/useTracking';

const LoginForm = () => {
  const location = useLocation();
  const auth = useAuth();
  const { trackEvent } = useTracking();
  const functions = useFunctions();
  const [error, setError] = useState<string>();
  const [submitting, setSubmitting] = useState(false);
  const [usePassword, setUsePassword] = useState(false);
  const [emailSent, setEmailSent] = useState<string>();
  const mounted = useMountedRef();
  const errorReporter = useErrorReporter();

  const {
    handleSubmit,
    register,
    clearErrors,
    formState: { errors },
  } = useForm<{ email: string; password?: string }>();

  const state = location.state as { from?: To; email?: string } | null;
  const from = state?.from;
  const prefilledEmail = state?.email;

  const loginWithEmail = async (email: string) => {
    setSubmitting(true);
    try {
      const methods = await fetchSignInMethodsForEmail(auth, email);
      if (methods.length === 0) {
        setError("Introwise account with this email doesn't exist");
      } else {
        const redirectUrl = `${window.origin}${from ? (typeof from === 'string' ? from : createPath(from)) : '/home'}`;
        try {
          await httpsCallable(functions, 'authSendSignInLink')({ email, redirectUrl });
          try {
            window.localStorage.setItem('introwise.signin.email', email);
          } catch (err) {
            // Do nothing
          }
          setEmailSent(email);
        } catch (err) {
          setError('Something went wrong. Please try again');
          errorReporter.report(new Error(`Failed to request sending sign in email: ${err}`));
        }
      }
    } catch (err) {
      const errorCode = err.code as string;
      if (errorCode === 'auth/invalid-email') {
        setError('Invalid email');
      } else {
        setError('Something went wrong. Please try again');
        errorReporter.report(new Error(`Failed to verify an email for a sign in link: ${err}`));
      }
    }
    setSubmitting(false);
  };

  const loginWithPassword = async (email: string, password: string) => {
    setSubmitting(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      trackEvent('Signed In', { method: 'password' });
    } catch (err) {
      const errorCode = err.code as string;
      if (errorCode === 'auth/invalid-email') {
        setError('Invalid email');
      } else if (errorCode === 'auth/user-disabled') {
        setError('Cannot log you in');
      } else if (errorCode === 'auth/user-not-found') {
        setError("Introwise account with this email doesn't exist");
      } else if (errorCode === 'auth/wrong-password') {
        setError('Invalid password');
      } else if (errorCode === 'auth/too-many-requests') {
        setError('Too many attempts. Please try again later or reset your password');
      } else {
        setError('Something went wrong. Please try again');
        errorReporter.report(new Error(`Failed to log in with a password: ${err}`));
      }
    }
    // Successful login causes the form to be unmounted
    if (mounted.current) {
      setSubmitting(false);
    }
  };

  if (emailSent) {
    return (
      <p>
        Check your <b>{emailSent}</b> inbox &mdash; we have just sent you a link to log in.
      </p>
    );
  }

  return (
    <form
      onSubmit={handleSubmit(async ({ email, password }) => {
        if (usePassword) {
          void loginWithPassword(email, password);
        } else {
          void loginWithEmail(email);
        }
      })}
    >
      <fieldset disabled={submitting}>
        <FormGroup>
          <Label>
            <Input
              {...register('email', {
                required: 'Please enter an email address',
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Please enter an email address',
                },
              })}
              hasError={!!errors.email}
              defaultValue={prefilledEmail || ''}
              type="email"
              autoComplete="email"
              placeholder="email@example.com"
            />
            <LabelText>Email</LabelText>
          </Label>
          {errors.email && <FormError>{errors.email.message}</FormError>}
        </FormGroup>

        {usePassword ? (
          <>
            <FormGroup>
              <Label>
                <Input
                  {...register('password', {
                    required: 'Please enter a password',
                    minLength: {
                      value: 6,
                      message: 'Too short, a password is at least 6 characters',
                    },
                    shouldUnregister: true,
                  })}
                  hasError={!!errors.password}
                  defaultValue=""
                  type="password"
                  autoComplete="current-password"
                />
                <LabelText>Password</LabelText>
              </Label>
              {errors.password && <FormError>{errors.password.message}</FormError>}
            </FormGroup>
            <FormGroup className={themeClasses({ display: 'flex', alignItems: 'center' })}>
              <Button primary type="submit" className={themeClasses({ width: '100%' })}>
                {submitting && <Spinner />}
                <span>Log in</span>
              </Button>
            </FormGroup>
            {error && (
              <FormGroup>
                <FormError>{error}</FormError>
              </FormGroup>
            )}
            <p>
              Forgot your password?{' '}
              <LinkStyled to="/reset-password" state={from ? { from } : null}>
                Get help logging in
              </LinkStyled>
            </p>
          </>
        ) : (
          <>
            <FormGroup className={themeClasses({ display: 'flex', alignItems: 'center' })}>
              <Button primary type="submit" className={themeClasses({ width: '100%' })}>
                {submitting && <Spinner />}
                <span>Continue with email</span>
              </Button>
            </FormGroup>
            {error && (
              <FormGroup>
                <FormError>{error}</FormError>
              </FormGroup>
            )}
            <p>
              We will send you a magic link to log in.{' '}
              <InlineButton
                onClick={() => {
                  setUsePassword(true);
                  clearErrors();
                }}
              >
                Use a password instead
              </InlineButton>
            </p>
          </>
        )}
      </fieldset>
    </form>
  );
};

export default LoginForm;
